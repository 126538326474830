.wrapper {
  width: 95%;
  margin: 0 auto;
  position: relative;
  z-index: 1;

  @media (min-width: 768px) {
    max-width: 90%;
  }

  @media (min-width: 1200px) {
    max-width: 1100px;
  }
}
