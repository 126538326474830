.widget {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  margin: 1rem 1rem 1rem 0;

  &Container {
    display: flex;
    align-items: center;
    padding: 0.5rem 1rem;
    color: #fff;
    border-radius: 30px;
    border: none;
    cursor: pointer;
    background-color: var(--buttonBg);

    &Text {
      margin-left: 0.5rem;
      font-size: 1rem;
      font-weight: 500;
      color: var(--buttonColor);
    }

    &:hover {
      background-color: var(--buttonBgHover);
    }
  }
}