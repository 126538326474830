@mixin cardContent {
  background-color: #fff;
  border-radius: 8px;
  padding: 0.5rem;
  text-align: center;
  border: 1px solid transparent;
  outline: none;
  font-size: 1rem;
  color: inherit;
  cursor: pointer;
}

.test {
  &Card {
    border-radius: 20px;
    padding: 2rem 1rem;
    margin: 2rem auto;
    display: flex;
    flex-direction: column;
    background: var(--cardBg);
    box-shadow: var(--shadow);

    @media screen and (min-width: 786px) {
      padding: 2rem;
      margin: 0 auto;
    }
  }

  &Container {
    width: 100%;
  }

  &Question {
    width: 100%;
    margin-bottom: 2rem;
    @media screen and (min-width: 786px) {
      width: 80%;
    }

    &Title {
      font-weight: 800;
      font-size: 1rem;
      margin-bottom: 1rem;
      @media screen and (min-width: 786px) {
        font-size: 1.2rem;
      }
    }

    &Ansewrs {
      margin-bottom: 2rem;
    }
  }

  &Progress {
    width: 100%;
    height: 30px;
    border-radius: 20px;
    background: #dcdce1;
    margin-bottom: 2rem;
    position: relative;
    z-index: 1;
    background: var(--grey);

    &Info {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 1rem;
      width: 4rem;
      height: 4rem;
      border-radius: 50%;
      border: 1px solid;

      &Count {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 0.8rem;

        @media screen and (min-width: 768px) {
          font-size: 1rem;
        }
      }
    }

    &Bar {
      background: var(--buttonBgHover);
      top: 0;
      left: 0;
      z-index: 2;
      position: absolute;
      height: 100%;
      border-radius: 20px;
    }
  }

  &AnswerList {
    width: 80%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    &Item {
      margin-bottom: 1rem;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
