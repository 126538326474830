.container-center {
  width: 100%;
  display: flex;
  height: auto;
  min-height: var(--minHeightMobileContainer);
  align-items: center;
  justify-content: center;

  @media screen and (min-width: 786px) {
    margin: 0;
    min-height: var(--minHeightContainer);
  }
}
