.breadcrumbs {
  margin: 2rem 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  &Link {
    text-decoration: underline;
    display: flex;
    color: var(--buttonBg);
  }
}