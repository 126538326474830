.quizResult {
  &Heading {
    width: 100%;
    text-align: center;
    margin-bottom: 3rem;

    @media screen and (min-width: 768px) {
      width: 90%;
    }
  }

  &List {
    width: 100%;
    margin-top: 2rem;
    border-radius: 17px;
    padding: 1rem;
    margin-bottom: 2rem;
  }

  &Container {
    width: 100%;
    padding: 1rem;
    border-radius: 17px;
    margin: 2rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: var(--cardBg);
    box-shadow: var(--shadow);

    @media screen and (min-width: 768px) {
      padding: 3rem;
      margin: 5rem 0;
    }
  }
}

.resultQuestion {
  font-weight: 600;
  margin-bottom: 2rem;
  text-align: center;
  font-size: 0.9rem;
  width: 100%;

  @media screen and (min-width: 768px) {
    width: 90%;
    font-size: 1rem;
  }

  &Code {
    margin-bottom: 1rem;
  }
}

.resultOptions {
  &Items {
    display: flex;
    margin-bottom: 1rem;
    @media screen and (min-width: 768px) {
      margin-bottom: 0.5rem;
    }

    &Answer {
      font-size: 0.9rem;
      margin: 0 0 0.5rem 0.5rem;
      @media screen and (min-width: 768px) {
        width: 90%;
        font-size: 1rem;
      }
    }
  }
}
