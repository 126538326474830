.modal-enter {
  opacity: 0;
  transform: scale(0.9);
}

.modal-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: opacity 300ms, transform 300ms;
}

.modal-exit {
  opacity: 1;
}

.modal-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.298);

  &-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    box-shadow: 0 7px 20px 0 rgba(16, 29, 58, 0.3);
    width: 95%;
    padding: 2rem;
    border-radius: 17px;
    color: var(--colorText);
    background: var(--modalBg);

    @media screen and (min-width: 768px) {
      width: 500px;
    }
    @media screen and (min-width: 1024px) {
      width: 500px;
    }
  }

  &-header {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1rem;
  }
}
