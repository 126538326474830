.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin: 1rem auto;
  @media screen and (min-width: 786px) {
    margin: 2rem auto;
  }
}
