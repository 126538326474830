@font-face {
  font-family: 'Montserrat Alternates';
  src: url('../fonts/MontserratAlternates-Light.ttf') format('truetype');
  font-weight: 300;
}

@font-face {
  font-family: 'Montserrat Alternates';
  src: url('../fonts/MontserratAlternates-Regular.ttf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'Montserrat Alternates';
  src: url('../fonts/MontserratAlternates-Medium.ttf') format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'Montserrat Alternates';
  src: url('../fonts/MontserratAlternates-Bold.ttf') format('truetype');
  font-weight: 700;
}