.card {
  position: relative;
  border-radius: 20px;
  padding: 4rem 3rem;
  width: 100%;
  margin-bottom: 1rem;
  text-align: center;
  cursor: pointer;
  background: var(--cardBg);
  box-shadow: var(--shadow);

  &_technologies {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.card:hover {
  animation: cardAnimation 1s linear;
  animation-iteration-count: infinite;

}

@keyframes cardAnimation {
  0% {
    transform: rotate(0deg);
  }
  60% {
    transform: rotate(5deg);
  }
  100% {
    transform: rotate(-5deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
