
:root {
  --minHeightContainer: 750px;
  --minHeightMobileContainer: 500px;

  .theme-light {
    --buttonColor: #e3efff;
    --buttonBg: #208DF2;
    --buttonBgHover: #1B51DE;
    --white: #fff;
    --cardBg: #f8fbff;
    --shadow: 0px 7px 20px 0px rgba(58, 109, 139, 0.30);
    --colorText: #334366;
    --grey: #dcdce1;
    --modalBg: var(--white);
  }

  .theme-dark {
    --buttonColor: #151c2a;
    --buttonBg: #7AC2F8;
    --buttonBgHover: #53ADF9;
    --white: #15223a;
    --cardBg: #253d5b;
    --shadow: 0 7px 20px 0 rgb(16 23 41);
    --colorText: #BFE1FA;
    --grey: #becce9;
    --modalBg: #1e334e;
  }
}

body {
  margin: 0;
  font-family: 'Montserrat Alternates', sans-serif;
  font-weight: 400;
  overflow: auto;
  position: relative;
  height: 100%;
  min-height: 100vh;

  &::before {
    position: fixed;
    width: 100%;
    height: 300px;
    opacity: 0.4;
    content: '';
    background: no-repeat url("../image/bg-left.svg");
    bottom: 0;
    left: 0;
    @media screen and (min-width: 768px) {
      width: 500px;
      height: 600px;
    }
  }

  &::after {
    display: none;
    @media screen and (min-width: 1024px) {
      display: block;
      position: fixed;
      width: 500px;
      height: 600px;
      z-index: 0;
      opacity: 0.4;
      content: '';
      background: no-repeat url("../image/bg-right.svg");
      bottom: 0;
      right: 0;
    }
  }
}

.layout {
  display: flex;
  flex-direction: column;
  background: var(--white);
  color: var(--colorText);
  height: 100%;
  min-height: 100vh;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

.recapcha {
  margin-bottom: 2rem;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;

}

pre {
  font-size: 0.85rem;
}

a {
  text-decoration: none;
  color: inherit;
}

.container {
  display: flex;
}

h1 {
  text-align: center;
  font-size: 46px;
  margin-bottom: 2rem;
  @media (max-width: 575.98px) {
    font-size: calc(46px / 2);
    margin-bottom: 1rem;
  }
}

h2 {
  font-size: 30px;
  text-align: center;
  margin-bottom: 2rem;
  @media (max-width: 575.98px) {
    font-size: 25px;
    margin-bottom: 1rem;
  }
}

h3 {
  font-size: 20px;
  @media (max-width: 575.98px) {
    font-size: 18px;
  }
}

.card-heading {
  text-align: center;
  margin-top: 4rem;
}

.modal-text-center {
  text-align: center;
  font-size: 1.3rem;
  margin-bottom: 2rem;
  color: var(--colorText);
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.fade-enter {
  opacity: 0;
  transition: opacity 200ms;
}

.fade-enter-active {
  opacity: 1;
}

.fade-exit {
  opacity: 1;
  transition: opacity 1s ease-in;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity 200ms;
}

$easy: cubic-bezier(0.39, 0.575, 0.565, 1);